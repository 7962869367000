import { BaseComponent } from "./base.component";



export class ListFilterHandler<
    TInput extends IFilterInput,
    TResult
> extends BaseComponent {

    private readonly PageSize = 5;
    filterInput: TInput;
    records: Array<TResult>;

    private _pageHandler: IPageHandler<TResult>;
    private readonly additionalRestOption?: (args: TInput) => void;


    /**
     *
     */
    constructor(additionalRestOption: (args: TInput) => void) {
        super();

        this._pageHandler = {
            items: [],
            pageSize: this.PageSize,
            pageNumber: 1
        }

        this.filterInput = {
            pageNumber: 1,
            pageSize: this.PageSize,            

        } as TInput

        this.additionalRestOption = additionalRestOption;
    }

    public setPageHandler(response: IPageHandler<TResult>, withReset: boolean) {
        this._pageHandler = response;
        if (withReset) {
            this.records = response.items;
        } else {
            response.items.forEach(item => {
                if (this.records.indexOf(item) == -1) {
                    this.records.push(item);
                }
            })
        }
    }

    public setFilters(nextPage: boolean) {
        this.filterInput.pageSize = this.PageSize;
        this.filterInput.pageNumber = this._pageHandler.pageNumber;

        if (this.additionalRestOption) {
            this.additionalRestOption(this.filterInput);
        }

        if (nextPage) {
            this.filterInput.pageNumber = ++this._pageHandler.pageNumber;
        } else {
            this.filterInput.pageNumber = 1;
        }
    }

    public get hasNext(): boolean {
        return this._pageHandler?.hasNextPage;
    }

    public get totalCount() {
        if (this._pageHandler) {
            return this._pageHandler.totalCount ?? 0
        }

        return 0;
    }
}

interface IPageHandler<TResult> {
    pageNumber?: number;
    pageSize?: number;
    totalCount?: number;
    totalRecords?: number;
    items?: Array<TResult> | null;
    readonly pages?: number;
    readonly hasPreviousPage?: boolean;
    readonly hasNextPage?: boolean;
}

interface IFilterInput {
    pageNumber?: number;
    pageSize?: number;
    totalCount?: number;
    totalRecords?: number;
}